import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { MakeaclaimService } from '../../services/makeaclaim/makeaclaim.service';
import { HelperProvider } from '../../providers/helper.provider';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-product',
    templateUrl: './makeAClaim.component.html',
    styleUrls: ['./makeAClaim.component.scss'],
styles: [`
   .d-none {
      display: block!important;
   }
`],
})
export class MakeAClaimComponent implements OnInit
{
    
    public data;
    public id;
    public title;
    public content;
    public country;
    public target;
    public selectedDoc: any;
    public selectedMacDownload: any;

    selectedTab: string = 'mac-tab-content-1';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private meta: Meta,
        private titleService: Title,
        public makeAClaimService: MakeaclaimService,
        public helper: HelperProvider,
        public translate: TranslateService
    ) {}
    
    onNavigate(event: any, target: string = '_self')
    {
        this.router.navigate([event.target.value]);
    }

    ngOnInit() 
    {
        this.route.params.subscribe((params: any) => {
                this.id = params['id'];
            }
        );

        this.makeAClaimService.getMakeAClaim().subscribe((claim: any) => {
            this.data = (claim.length > 0) ? claim[0].acf : claim.acf;
            if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
                this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
                this.titleService.setTitle(this.data.meta_tag_title.content);
            }

            if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
                this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
                this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
            }
        });
    }

    scrollTo(id)
    {
        const el = document.getElementById(id);
        let actualScroll = window.pageYOffset;
        const scrollInterval = setInterval(() => {
          if ( actualScroll >= el.offsetTop - 120 ) {
            clearInterval(scrollInterval);
          } else {
            actualScroll += 30;
            window.scrollTo(0, actualScroll);
          }
        }, 10);
    }

    showTab(id, $event)
    {
        this.selectedTab = id;
        const button = ( $event.target.tagName == 'BUTTON' ) ? $event.target : $event.target.parentNode;
        const parentButton = button.parentNode;
        const el = document.getElementById(id);
        const parentEl = el.parentNode;
        let actualActive = null;
        if( !el.classList.contains('active') ) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < parentEl.childNodes.length; i++) {
                const childEl = parentEl.childNodes[i] as HTMLElement;
                if (childEl.classList.contains('active')) {
                  actualActive = parentEl.childNodes[i];
                  actualActive.classList.remove('active');
                  break;
                }
            }
            el.classList.add('active');
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < parentButton.childNodes.length; i++) {
                if (parentButton.childNodes[i].classList && parentButton.childNodes[i].classList.contains('active')) {
                  actualActive = parentButton.childNodes[i];
                  actualActive.classList.remove('active');
                  break;
                }
            }
            button.classList.add('active');
        }
    }

    openList($event)
    {
        const button = ( $event.target.tagName == 'BUTTON' ) ? $event.target : $event.target.parentNode;
        const list = button.nextSibling;
        if( list.classList.contains('open') ) {
            list.classList.remove('open');
        } else {
            list.classList.add('open');
        }
    }

    selectOption($event, product: any, variableSelected: string)
    {
        const button = ( $event.target.tagName === 'BUTTON' ) ? $event.target : $event.target.parentNode;
        const list = button.parentNode.parentNode;
        const inputGroup = list.parentNode;
        const buttonSelected = inputGroup.children[0];
        buttonSelected.children[0].innerHTML = button.innerHTML;
        list.classList.remove('open');

        this[variableSelected] = product;
    }

    downloadFile(variableSelected: string)
    {
        if (this[variableSelected]) {
            window.open(this[variableSelected].file, '_blank');
        }
    }
}
