import { Injectable, OnInit } from "@angular/core";
import {
  Router,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../environments/environment";

declare var Vimeo;
declare var MapMarkers;
// declare var OneTrust; // Bloqueado
// declare var BrowseAloud; // Bloqueado

@Injectable({
  providedIn: "root",
})
export class HelperProvider {
  currentLang: string = "";
  currentUrl: string = "";

  ISOCODE: string = "isocode";

  constructor(
    public translate: TranslateService
  ) {
    this.currentLang = this.getLangFromDomain() || this.translate.currentLang || this.translate.defaultLang;
  }

  public getRouteLink(item: any) {
    if (typeof item === "object") {
      if (item.type_label) {
        if (item.type_label.toLowerCase() === "covid19") {
          return "/covid19/" + item.object_slug;
        }

        if (
          item.type.toLowerCase() === "custom" &&
          this.isValidHttpUrl(item.url)
        ) {
          return item.url;
        }
      }

      return "/" + item.object_slug;
    }

    return "/" + item;
  }

  public getTargetRouteLink(item: any) {
    if (typeof item === "object") {
      if (item.hasOwnProperty("target") && item.target) {
        return item.target;
      }
    }

    return "_self";
  }

  public isValidHttpUrl(data) {
    let url;

    try {
      url = new URL(data);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  public setDynamicEndpoint(isoCode: string) {
    this.removeCurrentIsoCode();
    this.removeDynamicEndPoint();

    this.setCurrentIsoCode(isoCode);
    return sessionStorage.setItem(
      window.location.host + "_endpoint",
      this.getDynamicStringEndpoint(isoCode)
    );
  }

  public getDynamicEndpoint() {
    return sessionStorage.getItem(window.location.host + "_endpoint");
  }

  public setCurrentIsoCode(isoCode: string) {
    return sessionStorage.setItem(this.ISOCODE, isoCode);
  }

  public getCurrentIsoCode() {
    return sessionStorage.getItem(this.ISOCODE);
  }

  public removeCurrentIsoCode() {
    return sessionStorage.removeItem(this.ISOCODE);
  }

  public getDynamicStringEndpoint(isoCode) {
    return "md.php?c=" + isoCode;
  }

  public removeDynamicEndPoint() {
    return sessionStorage.removeItem(window.location.host + "_endpoint");
  }

  public removeSessionStorage() {
    this.removeCurrentIsoCode();
    this.removeDynamicEndPoint();
  }

  public getEndpointCurrentHost(checkDynamicEndpoint: boolean = true) {
    let endpoint = "";

    if (checkDynamicEndpoint) {
      const dynamicEndpoint = this.getDynamicEndpoint();

      if (dynamicEndpoint) {
        return dynamicEndpoint;
      }
    }

    const endpoints = this.getEndpoints();
    // tslint:disable-next-line:forin
    for (const i in endpoints) {
      const key = Object.keys(endpoints[i])[0];
      let host = window.location.host;
      host = host.replace("www.", "");

      if (host === key) {
        endpoint = endpoints[i][key];
        break;
      }
    }

    return endpoint;
  }

  getLangFromDomain(): string {
    const host = window.location.host.replace("www.", "");
    const endpoints = this.getEndpoints();
    for (const endpoint of endpoints) {
      if (endpoint.hasOwnProperty(host)) {
        const langCode = endpoint[host].split('=')[1];
        return langCode;
      }
    }
    return '';
  }

  public getEndpoints() {
    const detail = this.capitalize(environment.detail);
    return this['listLanguages' + detail + 'Endpoint']();
  }

  public listLanguagesProEndpoint() {
    return [
      {
        "localhost:4200": "md.php?c=es",
      },
      {
        "cnpsantander.com": "md.php?c=original",
      },
      {
        "cnpsantander.de": "md.php?c=de",
      },
      {
        "cnpsantander.es": "md.php?c=es",
      },
      {
        "cnpsantander.pl": "md.php?c=pl",
      },
      {
        "cnpsantander.it": "md.php?c=it",
      },
      {
        "cnpsantander.at": "md.php?c=at",
      },
      {
        "no.cnpsantander.com": "md.php?c=no",
      },
      {
        "cnpsantander.se": "md.php?c=se",
      },
      {
        "cnpsantander.fi": "md.php?c=fi",
      },
      {
        "cnpsantander.dk": "md.php?c=dk",
      },
      {
        "cnpsantander.nl": "md.php?c=nl",
      },
      {
        "cnpsantander.be": "md.php?c=be",
      },
      {
        "cnpsantander.pt": "md.php?c=pt",
      },
    ];
  }

  public listLanguagesPreEndpoint() {
    return [
      {
        "localhost:4200": "md.php?c=es",
      },
      {
        "pre.cnpsantander.com": "md.php?c=original",
      },
      {
        "pre.cnpsantander.de": "md.php?c=de",
      },
      {
        "pre.cnpsantander.es": "md.php?c=es",
      },
      {
        "pre.cnpsantander.pl": "md.php?c=pl",
      },
      {
        "pre.cnpsantander.it": "md.php?c=it",
      },
      {
        "pre.cnpsantander.at": "md.php?c=at",
      },
      {
        "pre.no.cnpsantander.com": "md.php?c=no",
      },
      {
        "pre.cnpsantander.se": "md.php?c=se",
      },
      {
        "pre.cnpsantander.fi": "md.php?c=fi",
      },
      {
        "pre.cnpsantander.dk": "md.php?c=dk",
      },
      {
        "pre.cnpsantander.nl": "md.php?c=nl",
      },
      {
        "pre.cnpsantander.be": "md.php?c=be",
      },
      {
        "pre.cnpsantander.pt": "md.php?c=pt",
      },
    ];
  }

  public listLanguagesDevEndpoint() {
    return [
      {
        "localhost:4200": "md.php?c=es",
      },
      {
        "dev.cnpsantander.com": "md.php?c=original",
      },
      {
        "dev.cnpsantander.de": "md.php?c=de",
      },
      {
        "dev.cnpsantander.es": "md.php?c=es",
      },
      {
        "dev.cnpsantander.pl": "md.php?c=pl",
      },
      {
        "dev.cnpsantander.it": "md.php?c=it",
      },
      {
        "dev.cnpsantander.at": "md.php?c=at",
      },
      {
        "dev.no.cnpsantander.com": "md.php?c=no",
      },
      {
        "dev.cnpsantander.se": "md.php?c=se",
      },
      {
        "dev.cnpsantander.fi": "md.php?c=fi",
      },
      {
        "dev.cnpsantander.dk": "md.php?c=dk",
      },
      {
        "dev.cnpsantander.nl": "md.php?c=nl",
      },
      {
        "dev.cnpsantander.be": "md.php?c=be",
      },
      {
        "dev.cnpsantander.pt": "md.php?c=pt",
      },
    ];
  }

  public getEndpoint(search: string = "") {
    let endpoint = "";

    if (search) {
      const endpoints = this.getEndpoints();

      // tslint:disable-next-line:forin
      for (const i in endpoints) {
        const key = Object.keys(endpoints[i])[0];
        if (key.indexOf(search) > -1) {
          endpoint = endpoints[i][key];
          break;
        }
      }
    }

    return endpoint;
  }

  public getLengthObjectKeys(object: {}) {
    if (typeof object === "object" && object !== undefined && object !== null) {
      return Object.keys(object).length;
    }

    return 0;
  }

  public listSlugProducts() {
    return [
      "products",
      "productos",
      "produkte",
      "producten",
      "produits",
      "tuotteet",
      "produkter",
      "produkty",
      "prodotti",
      "produtos",
    ];
  }

  public getVimeoIdFromUrl(url: string = "") {
    const match = /vimeo.*\/(\d+)/i.exec(url);

    if (match) {
      return match[1];
    }

    return false;
  }

  public loadVimeo(container, videoId) {
    let player = null;
    if (container) {
      const options = {
        id: parseInt(videoId, 0),
        width: 640,
        loop: false,
        dnt: 1
      };

      player = new Vimeo.Player(container, options);
    }

    return player;
  }

  getExtensionFromString(value: string) {
    return value.substr(value.lastIndexOf(".") + 1);
  }

  capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }

  split(text) {
    if (typeof text === 'string') {
      return text.split(',').map(function (item) {
        return item.trim();
      });
    }

    return [text.trim()];
  }

  // async loadReachDeckScript() { // Bloqueado
  //   const isMobile = window.innerWidth <= 800;
  //   if (!isMobile) {
  //     window['_baFixedNav'] = ['header'];
  //   }

  //   if (window.location.hostname === 'localhost') {
  //     return false;
  //   }

  //   const reachDeck = this.getReachDeck();

  //   return reachDeck ? Promise.resolve(reachDeck) : new Promise((resolve, reject) => {

  //     const SRC = 'https://www.browsealoud.com/plus/scripts/3.1.0/ba.js';
  //     const INTEGRITY = 'sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ==';

  //     try {
  //       const findScript = () => document.querySelector<HTMLScriptElement>(`script[src^="${SRC}"]`);
  //       const injectScript = () => {

  //         // eslint-disable-next-line @typescript-eslint/no-shadow
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const script = document.createElement('script');

  //         script.src = SRC;
  //         script.type = 'text/javascript';
  //         script.defer = true;
  //         script.async = true;
  //         script.integrity = INTEGRITY;
  //         script.crossOrigin = 'anonymous';

  //         (document.body).appendChild(script);

  //         return script;
  //       };

  //       const script = findScript() || injectScript();

  //       script.addEventListener('error', (error) => {
  //         reject(new Error('Unable to load Reach Deck plugin'));
  //       });
  //       script.addEventListener('load', () => resolve(this.getReachDeck()));
  //     } catch (e) { reject(e); }
  //   });

  // }

  // reachDeckBottomToolbar() { // Bloqueado
  //   const bottomNavId = 'footer';
  //   const showHide = () => {
  //     const isMobile = window.innerWidth <= 800;
  //     // tslint:disable-next-line:no-shadowed-variable
  //     const fixedNav = document.getElementById(bottomNavId);
  //     let show = false;
  //     if (isMobile) {
  //       const toolbar = document.getElementById('__bs_entryDiv')?.lastElementChild?.shadowRoot || null;
  //       if (toolbar !== null) {
  //         const childs = Array.from(toolbar.childNodes);
  //         // loop toolbar div to see if toolbar is active
  //         // tslint:disable-next-line:prefer-for-of
  //         for (let t = 0; t < childs.length && !show; t++) {
  //           show = false;
  //           const currentChild = childs[t];
  //           if (currentChild['id'] === 'th_toolbar') {
  //             show = true;
  //           }
  //         }
  //       }
  //     }

  //     if (fixedNav) {
  //       fixedNav.style.bottom = show ? '60px' : '';
  //     }
  //   }
  //   // function to set a listener on the toolbar element for opening and closing
  //   const setToolbarListener = () => {
  //     const checkForToolbar = setInterval(() => {
  //       const toolbar = document.getElementById('__bs_entryDiv')?.lastElementChild?.shadowRoot || null;
  //       if (toolbar !== null) {
  //         // Options for the observer (which mutations to observe)
  //         const config = { childList: true };
  //         // Create an observer instance linked to the callback function
  //         const observer = new MutationObserver(showHide);
  //         // Start observing the target node for configured mutations
  //         observer.observe(toolbar, config);
  //         clearInterval(checkForToolbar);
  //       }
  //     }, 100);
  //   }
  //   // load listener to apply the other listeners on the toolbar
  //   window.addEventListener('load', () => {
  //     const checkBA = setInterval(() => {
  //       // tslint:disable-next-line:triple-equals
  //       if (typeof (BrowseAloud) != 'undefined') {
  //         showHide();
  //         setToolbarListener();
  //         clearInterval(checkBA);
  //       }
  //     }, 100);
  //   });

  //   window.addEventListener('resize', showHide);
  // }

  // getReachDeck() { // Bloqueado
  //   return window && window.hasOwnProperty('ReachDeck') || window.hasOwnProperty('BrowseAloud');
  // }

  // listenOneTrustChanged() { // Bloqueado
  //   document.addEventListener('OneTrust_Initialized', (data) => {
  //     OneTrust.OnConsentChanged((event: any) => {
  //       if (event.type === 'consent.onetrust') {
  //         const detail = event.detail; // "C0001" necesarias, "C0003" funcionales y no obligatorias
  //         if (Array.isArray(detail)) {
  //           if (typeof BrowseAloud !== 'undefined') {
  //             // si no está C0003 en detail desactivamos, en caso contrario activamos.
  //             if (detail.indexOf('C0003') > -1) {
  //               BrowseAloud.enableBrowsealoudAnalytics();
  //             } else {
  //               BrowseAloud.disableBrowsealoudAnalytics();
  //             }
  //           }
  //         }
  //       }
  //     });
  //   });
  // }

  // disabledBrowsealoudAnalytics() { // Bloqueado
  //   if (typeof BrowseAloud !== 'undefined') {
  //     BrowseAloud.disableBrowsealoudAnalytics();
  //   }
  // }

  // refreshReachDeckToolbar() { // Bloqueado
  //   if (typeof BrowseAloud !== 'undefined') {
  //     if (BrowseAloud.isToolbarActive()) {
  //       try {
  //         BrowseAloud.panel.toggleBar(!0); // off
  //         BrowseAloud.panel.toggleBar(!0); // on
  //         const n = setInterval((() => {
  //           const entryDiv = document.getElementById('__bs_entryDiv');
  //           if (entryDiv) {
  //             entryDiv.firstElementChild.shadowRoot.getElementById('th_play').click();
  //             clearInterval(n);
  //           }
  //         }), 500)
  //       } catch (e) { }
  //     }
  //   }
  // }

  //#region Google Maps

  // async loadGoogleMapsScript() {
  //   const reachDeck = this.getGoogleMaps();

  //   return reachDeck ? Promise.resolve(reachDeck) : new Promise((resolve, reject) => {

  //     const languageMap = {
  //       'es': 'es',  // España
  //       'pt': 'pt',  // Portugal
  //       'at': 'de',  // Austria (utiliza alemán)
  //       'it': 'it',  // Italia
  //       'de': 'de',  // Alemania
  //       'nl': 'nl',  // Holanda (Países Bajos)
  //       'be': 'nl',  // Bélgica ('nl' para neerlandés)
  //       'pl': 'pl',  // Polonia
  //       'dk': 'da',  // Dinamarca
  //       'fi': 'fi',  // Finlandia
  //       'se': 'sv',  // Suecia
  //       'no': 'no'   // Noruega
  //     };

  //     const currentDomain = window.location.hostname.split('.').pop();

  //     this.currentLang = languageMap[currentDomain] || 'en';

  //     const SRC = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDqMLsjV03bTt3u3fO4u0QJeqpIOzjp7c4&language=${this.currentLang}`;

  //     console.log("LANG:", this.currentLang);

  //     try {
  //       const findScript = () => document.querySelector<HTMLScriptElement>(`script[src^="${SRC}"]`);
  //       const injectScript = () => {

  //         // eslint-disable-next-line @typescript-eslint/no-shadow
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const script = document.createElement('script');

  //         script.src = SRC;
  //         script.type = 'text/javascript';
  //         script.defer = true;
  //         script.async = true;
  //         script.crossOrigin = 'anonymous';

  //         (document.body).appendChild(script);

  //         return script;
  //       };

  //       const script = findScript() || injectScript();

  //       script.addEventListener('error', (error) => {
  //         reject(new Error('Unable to load google maps plugin'));
  //       });
  //       script.addEventListener('load', () => resolve(this.getGoogleMaps()));
  //     } catch (e) { reject(e); }
  //   });

  // }
  
  // getGoogleMaps() {
  //   return window && window.hasOwnProperty('google');
  // }

  // setButtonNameGoogleMaps() {
  //   const googleMap = document.getElementById('google-map');

  //   if (googleMap) {
  //     const gmStyle = googleMap.getElementsByClassName('gm-style');
  //     if (gmStyle.length > 0) {
  //       const buttons = gmStyle[0].querySelectorAll('button');
  //       // tslint:disable-next-line:prefer-for-of
  //       for (let i = 0; i < buttons.length; i++) {
  //         const button = buttons[i];
  //         if (!button.name) {
  //           button.name = ((new Date()).getTime() + i).toString();
  //         }
  //       }
  //     }
  //   }
  // }

  // equalheight(container: string) {
  //   let currentTallest = 0;
  //   let currentRowStart = 0;
  //   let rowDivs = [];
  //   let $el;
  //   let topPosition = 0;

  //   Array.from(document.querySelectorAll(container)).forEach((el, i) => {
  //     // @ts-ignore
  //     el.style.height = "auto";
  //     // @ts-ignore
  //     topPosition = el.offsetTop;
  //     if (currentRowStart != topPosition) {
  //       for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
  //         this.setHeight(rowDivs[currentDiv], currentTallest)
  //       }
  //       rowDivs.length = 0;
  //       currentRowStart = topPosition;
  //       currentTallest = parseFloat(getComputedStyle(el, null).height.replace("px", ""))
  //       rowDivs.push(el);
  //     } else {
  //       rowDivs.push(el);
  //       currentTallest = (currentTallest < parseFloat(getComputedStyle(el, null).height.replace("px", ""))) ? (parseFloat(getComputedStyle(el, null).height.replace("px", ""))) : (currentTallest);
  //     }
  //     for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
  //       this.setHeight(rowDivs[currentDiv], currentTallest)
  //     }
  //   })
  // }

  // setHeight(el: any, val: any) {
  //   if (typeof val === "function") {
  //     val = val();
  //   }
  //   if (typeof val === "string") {
  //     el.style.height = val;
  //   } else {
  //     el.style.height = val + "px";
  //   }
  // }

  getGoogle()
    {
        return window && window.hasOwnProperty('google');
    }

    loadGoogleMaps()
    {
        const google = this.getGoogle();

        return google ? Promise.resolve( google ) : new Promise( (resolve, reject) => {

            const SRC = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDqMLsjV03bTt3u3fO4u0QJeqpIOzjp7c4&language=${this.currentLang}`;

            try {
                const findScript = () => document.querySelector<HTMLScriptElement>(`script[src^="${SRC}"]`);
                const injectScript = () => {

                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    // tslint:disable-next-line:no-shadowed-variable
                    const script = document.createElement('script');

                    script.src = SRC;
                    script.type = 'text/javascript';
                    script.defer = true;
                    script.async = true;
                    script.crossOrigin = 'anonymous';

                    (document.body).appendChild(script);

                    return script;
                };

                const script = findScript() || injectScript();

                script.addEventListener('error', (error) => {
                    reject(new Error('Unable to load google maps'));
                });
                script.addEventListener('load', () => resolve(this.getGoogle()));
            } catch (e) {
                reject(e);
            }
        });
    }

    getMapMarkers()
    {
        return typeof MapMarkers !== 'undefined';
    }

    loadMapMarkersScript()
    {
        const mapMarkers = this.getMapMarkers();

        return mapMarkers ? Promise.resolve( mapMarkers ) : new Promise( (resolve, reject) => {

            const SRC = '../assets/scripts/map-markers.js';

            try {
                const findScript = () => document.querySelector<HTMLScriptElement>(`script[src^="${SRC}"]`);
                const injectScript = () => {

                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    // tslint:disable-next-line:no-shadowed-variable
                    const script = document.createElement('script');

                    script.src = SRC;
                    script.type = 'text/javascript';
                    script.defer = true;
                    script.async = true;

                    (document.body).appendChild(script);

                    return script;
                };

                const script = findScript() || injectScript();

                script.addEventListener('error', (error) => {
                    reject(new Error('Unable to load map markers'));
                });
                script.addEventListener('load', () => resolve(this.getMapMarkers()));
            } catch (e) {
                reject(e);
            }
        });
    }

    buildMapMarkers(mapDataJSON)
    {
        const name = 'staticmap';
        window[name] = new MapMarkers(name, mapDataJSON);
        window[name].importJSON(mapDataJSON);
        window[name].showAllMapMarkers();
        window[name].loadEvents();
    }


}
