import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product/product.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HelperProvider } from '../../providers/helper.provider';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    styles: [`
       .d-none {
          display: block!important;
       }
    `],
})
export class ProductComponent implements OnInit
{
    public data;
    public itemList;
    public id;

    public headerImg;
    public title;
    public subTitle;
    public buttonText;
    public content;
    public country;
    public target;

    constructor(
        private productsService: ProductService,
        private route: ActivatedRoute,
        private router: Router,
        private meta: Meta,
        private titleService: Title,
        public helper: HelperProvider,
        public translate: TranslateService
    ) {}

    ngOnInit()
    {
        this.route.params.subscribe((params: any) => {
                this.id = params['id'];
                this.getCategory();
            }
        );

        // sobreescribimos la url
        this.route.data.subscribe((params: any) => {
            if (params.hasOwnProperty('id')) {
                this.id = params.id;
                this.getCategory();
            }
        });
    }

    getCategory()
    {
        this.productsService.getCategory(this.id).subscribe((category: any) => {
            this.data = (category.length > 0) ? category[0].acf : category.acf;

            // SEO Tags
            if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
                this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
                this.titleService.setTitle(this.data.meta_tag_title.content);
            }

            if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
                this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
                this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
            }
        });
    }

    closedWindowHandler(event: any)
    {
        // console.log(event);
    }

    accordionItem($event)
    {
        const title = ( $event.target.classList.contains('faq__accordion__item__title') ) ? $event.target : $event.target.parentNode;
        const item = title.parentNode;
        if( item.classList.contains('open') ) {
            item.classList.remove('open');
        } else {
            item.classList.add('open');
        }
    }

    openExplain($event)
    {
        $event.preventDefault();
        const link = ( $event.target.classList.contains('link') ) ? $event.target : $event.target.parentNode;
        const item = link.parentNode;
        const box = item.nextSibling;
        const wrap = item.parentNode;
        if ( box.classList.contains('open') ) {
            link.classList.remove('open');
            box.classList.remove('open');
        } else {
            // tslint:disable-next-line:prefer-for-of
            for( let i = 0; i < wrap.childNodes.length; i++ ) {
                if (wrap.childNodes[i].classList.contains('open')) {
                    wrap.childNodes[i].classList.remove('open');
                }
                document.querySelectorAll('.pcp-cover-with__item__link').forEach(function(links) {
                    links.classList.remove('open');
                });
            }
            link.classList.add('open');
            box.classList.add('open');
        }
    }

    onNavigate(event: any, target: string = '_self')
    {
        this.router.navigate([event.target.value]);
    }

    scrollTo(id)
    {
        const el = document.getElementById(id);
        let actualScroll = window.pageYOffset;
        const scrollInterval = setInterval(() => {
            if ( actualScroll >= el.offsetTop - 120 ) {
                clearInterval(scrollInterval);
            } else {
                actualScroll += 30;
                window.scrollTo(0, actualScroll);
            }
        }, 10);
    }

}
