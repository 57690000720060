import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class TranslateProvider
{

    defaultLang: string = 'es';

    constructor(
        public translate: TranslateService
    ) {
        if (!this.currentLang()) {
            this.translate.setDefaultLang(this.defaultLang);
        }
    }

    changeLanguage(lang: string = 'es')
    {
        this.translate.use(lang);
    }

    currentLang()
    {
        return this.translate.currentLang;
    }
}
