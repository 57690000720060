import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { HelperProvider } from './providers/helper.provider';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit
{

  constructor(
      private router: Router,
      private translate: TranslateService,
      public helperProvider: HelperProvider
  ) { }

  ngOnInit()
  {
    this.translate.setDefaultLang('en');

    this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        window.scrollTo(0, 0);

        // Eliminado el bloque relacionado con ReachDeck
        // if (e.id !== 1) {
        //   setTimeout(() => {
        //     this.helperProvider.refreshReachDeckToolbar();
        //   }, 1500);
        // }
      }
    });

    // Eliminado el listener relacionado con ReachDeck
    // document.addEventListener('refresh-reach-deck', (data) => {
    //   setTimeout(() => {
    //     this.helperProvider.refreshReachDeckToolbar();
    //   }, 1000);
    // });

  }

  ngAfterViewInit()
  {
    // Eliminado el bloque relacionado con ReachDeck
    // setTimeout(() => {
    //   this.helperProvider.loadReachDeckScript().then(() => {
    //     this.helperProvider.reachDeckBottomToolbar();
    //     this.helperProvider.disabledBrowsealoudAnalytics();
    //   });
    // }, 3000);
  }
}
