import {Component, OnInit, HostListener, ChangeDetectorRef, Inject} from '@angular/core';
import { HomeService } from '../../services/home/home.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HelperProvider } from '../../providers/helper.provider';
import { LanguagesService } from '../../services/languages.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit
{

  public data;
  public logo;
  public langs: any = [];
  public megaMenu: any;
  selectedLangText: string = '';
  defaultSelectedLangText: string = '';

  constructor(
      private homeService: HomeService,
      private router: Router,
      private translate: TranslateService,
      private activatedRoute: ActivatedRoute,
      private ref: ChangeDetectorRef,
      public helper: HelperProvider,
      public languages: LanguagesService,
      @Inject(DOCUMENT) public document: Document
  ) {

  }

  ngOnInit()
  {
    this.getDataAndLanguages();
  }

  getDataAndLanguages(isoCode: any = null)
  {
    this.languages.getLanguages().subscribe((langs: any) => {
      this.langs = [];
      if (langs.length > 0) {
        //Default language = first language
        this.defaultSelectedLangText = langs[0].acf.languages.lang_1.text;
        const currentIsoCode = this.helper.getCurrentIsoCode();
        for (const i in langs[0].acf.languages) {
          if (langs[0].acf.languages[i].text) {
            if (langs[0].acf.languages[i].isocode === currentIsoCode) {
              this.selectedLangText = langs[0].acf.languages[i].text;
            }
            this.langs.push(langs[0].acf.languages[i]);
          }
        }
      }
      
      if (this.langs.length === 0) {
        this.helper.removeSessionStorage();
        this.selectedLangText = null;
        isoCode = null;
      }

      this.getLogo(isoCode);
      this.getHeader(isoCode);
      this.getMegaMenuProducts(isoCode);

      let currentIsocode = this.helper.getCurrentIsoCode();

      if (currentIsocode) {
        if (currentIsocode === 'be') {
          currentIsocode = 'nl-be';
        }

        if (currentIsocode === 'be-fr') {
          currentIsocode = 'fr';
        }

        this.document.documentElement.lang = currentIsocode;
      }
    });
  }


  resetLanguage()
  {
    this.helper.removeSessionStorage();
    this.selectedLangText = null;
    /*
    // estamos en la home
    if (this.router.getCurrentNavigation() === null) {
      this.getDataAndLanguages();
    }

     */
  }

  getHeader(isoCode: any = null)
  {
    this.homeService.getHeader(isoCode).subscribe((menu: any) => {
      this.data = menu.items;
      this.ref.detectChanges();
    });
  }

  getLogo(isoCode: any = null)
  {
    this.homeService.getLogo(isoCode).subscribe((logo: any) => {
      this.logo = (logo.length > 0) ? logo[0].acf : logo.acf;
    });
  }

  getMegaMenuProducts(isoCode: any = null)
  {
    this.homeService.getMegaMenuProducts(isoCode).subscribe((megamenu: any) => {
      this.megaMenu = (megamenu.length > 0) ? megamenu[0].acf : megamenu.acf;
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: any)
  {
    const element = document.getElementById('header');
    if (element) {
      if (window.pageYOffset > 150) {
        element.classList.add('header--sticky');
      } else {
        element.classList.remove('header--sticky');
      }
    }
  }

  closeMobileMenu()
  {
    const element = document.getElementById('header');
    element.classList.remove('header--openMob');
  }

  toggleMenuMob(e: any)
  {
    const element = document.getElementById('header');
    if ( element.classList.contains('header--openMob') ) {
      element.classList.remove('header--openMob');
    } else {
      element.classList.add('header--openMob');
    }
  }

  toggleMobCat($event)
  {
    const element = ($event.srcElement.classList.contains('mobile-submenu') ? $event.srcElement : $event.srcElement.parentNode );
    const list = element.parentNode.parentNode;
    if( list.classList.contains('listOpen') ) {
      list.classList.remove('listOpen');
    } else {
      list.classList.add('listOpen');
    }
  }

  changeLang(lang: any)
  {
    this.helper.setDynamicEndpoint(lang.isocode);
    setTimeout(() => {
      this.router.navigate(['/'], { replaceUrl: true }).then(() => {
        location.reload();
      });
    }, 600);
  }

  mouseEnter($event: any)
  {
    if ($event) {
      const fromElement = $event.srcElement;
      const headerEl = document.getElementById('header');
      if (fromElement.classList.contains('products') && !headerEl.classList.contains('header--sticky') ) {
        headerEl.classList.add('header--sticky');
      }
    }
  }
  mouseLeave($event: any)
  {
    if ($event) {
      const fromElement = $event.srcElement;
      const headerEl = document.getElementById('header');
      if (!fromElement.classList.contains('products') && headerEl.classList.contains('header--sticky')) {
        document.getElementById('header').classList.remove('header--sticky');
      }
    }
  }
}
