import { Component, OnInit } from '@angular/core';
import { HelperProvider } from '../../providers/helper.provider';
import { ProductService } from '../../services/product/product.service';
import { ActivatedRoute } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-simple-product',
  templateUrl: './simple-product.component.html',
  styleUrls: ['./simple-product.component.scss']
})
export class SimpleProductComponent implements OnInit
{

  data: any;
  selectedMacDownload: any;

  vimeoUrlVideo: any;

  constructor(
      public helper: HelperProvider,
      public productService: ProductService,
      public route: ActivatedRoute,
      private meta: Meta,
      private titleService: Title,
      private sanitizer: DomSanitizer,
      private deviceService: DeviceDetectorService
  ) { }

  ngOnInit()
  {
    this.route.params.subscribe((params: any) => {
          if (params.hasOwnProperty('id')) {
            this.getSimpleProduct(params['id']);
          }
        }
    );
    // sobreescribimos la url
    this.route.data.subscribe((params: any) => {
      if (params.hasOwnProperty('id')) {
        this.getSimpleProduct(params.id);
      }
    });
  }

  getSimpleProduct(slug)
  {
    this.productService.getSimpleProduct(slug).subscribe((product: any) => {
      this.data = (product.length > 0) ? product[0].acf : product.acf;

      if (this.data && this.data.hasOwnProperty('meta_tag_title')) {
        this.data.meta_tag_title.content = this.data.meta_tag_title.content.replace(/<[^>]*>/g, '');
        this.titleService.setTitle(this.data.meta_tag_title.content);
      }

      if (this.data && this.data.hasOwnProperty('meta_tag_description')) {
        this.data.meta_tag_description.content = this.data.meta_tag_description.content.replace(/<[^>]*>/g, '');
        this.meta.updateTag({ name: 'description', content: this.data.meta_tag_description.content });
      }
    });
  }

  openExplain(event: any, index = 0)
  {
    event.preventDefault();

    const link_id = 'explanatory_content_open_link_' + index;
    let content_id = null;
    if (this.deviceService.isMobile() || window.innerWidth < 1023) {
      content_id = 'explanatory_content_mobile_' + index;
    } else {
      content_id = 'explanatory_content_desktop_' + index;
    }
    const link = document.getElementById(link_id);
    const content = document.getElementById(content_id);

    document.querySelectorAll('.pcp-cover-with__explain').forEach((content) => {
      if (content.id !== content_id) {
        content.classList.remove('open');
      }
    });
    document.querySelectorAll('.pcp-cover-with__item__link').forEach((link) => {
      if (link.id !== link_id) {
        link.classList.remove('open');
      }
    });

    setTimeout(() => {
      if (content) {
        const arrow = content.getElementsByClassName('arrow_desktop');
        const imageLink = link.getElementsByTagName('img');

        if (arrow && arrow.length > 0) {
          var x = event.clientX;

          const parent_link = link.parentElement.getBoundingClientRect();
          const content_rect = content.getBoundingClientRect();

          // por la izquierda
          if (x >= parent_link.x && x >= content_rect.x) {
            arrow[0].setAttribute('style', 'left:' + ((link.parentElement.offsetLeft - content_rect.x) + 20) + 'px');
          }
        }

        if ( content.classList.contains('open') ) {

          if (imageLink && imageLink.length > 0) {
            imageLink[0].style.filter = 'brightness(1) invert(0) grayscale(0)';
            imageLink[0].style.transform = 'rotate(0deg)';
          }

          link.classList.remove('open');
          content.classList.remove('open');
        } else {

          if (imageLink && imageLink.length > 0) {
            imageLink[0].style.filter = 'brightness(1) invert(1) grayscale(1)';
            imageLink[0].style.transform = 'rotate(180deg)';
          }

          link.classList.add('open');
          content.classList.add('open');
        }
      }
    });
  }

  openList(event: any)
  {
    const button = ( event.target.tagName == 'BUTTON' ) ? event.target : event.target.parentNode;
    const list = button.nextSibling;
    if( list.classList.contains('open') ) {
      list.classList.remove('open');
    } else {
      list.classList.add('open');
    }
  }

  selectOption(event: any, product: any, variableSelected: string)
  {
    const button = ( event.target.tagName === 'BUTTON' ) ? event.target : event.target.parentNode;
    const list = button.parentNode.parentNode;
    const inputGroup = list.parentNode;
    const buttonSelected = inputGroup.children[0];
    buttonSelected.children[0].innerHTML = button.innerHTML;
    list.classList.remove('open');

    this[variableSelected] = product;
  }

  downloadFile(variableSelected: string)
  {
    if (this[variableSelected]) {
      window.open(this[variableSelected].file, '_blank');
    }
  }

}
