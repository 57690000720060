import { Component, Input, OnInit } from '@angular/core';
import { HelperProvider } from '../../../providers/helper.provider';

@Component({
  selector: 'app-contact-products-nomodal',
  templateUrl: './contact-products-nomodal.component.html',
  styleUrls: ['./contact-products-nomodal.component.scss']
})
export class ContactProductsNomodalComponent implements OnInit
{

  @Input() countryName;
  @Input() countryNameTranslation;
  @Input() countryProducts;

  constructor(
      public helperProvider: HelperProvider
  ) { }

  ngOnInit() {
    console.log("PRODUCTS:", this.countryProducts);
    console.log("COUNTRY:", this.countryName);
    console.log("COUNTRY TRANSLATION:", this.countryNameTranslation);
  }
}
