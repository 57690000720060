import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-block-cta',
  templateUrl: './block-cta.component.html',
  styleUrls: ['./block-cta.component.scss']
})
export class BlockCtaComponent implements OnInit
{

  @Input() data;

  constructor(
      public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

}
