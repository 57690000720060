import {Component, Input, OnInit} from '@angular/core';
import {HelperProvider} from '../../providers/helper.provider';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-block-image',
  templateUrl: './block-image.component.html',
  styleUrls: ['./block-image.component.scss']
})
export class BlockImageComponent implements OnInit {

  @Input() data;

  constructor(
      public helper: HelperProvider,
      public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {}
}
